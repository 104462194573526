import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetcher } from '../../shared/fetcher';
import { LocalStorageKeys } from '../../shared/models/constants';
import { User } from '../../shared/models/user';
import { Actions } from '../actions';
import { FetchUserPayload } from '../payloads/fetch-user.payload';
import { SetNewUserPopupVisibilityPayload } from '../payloads/set-new-user-popup-visibility.payload';

export function* fetchUserSaga() {
  yield takeLatest(Actions.USER_FETCH_REQUESTED, fetchUser);
}

function* fetchUser(action: PayloadAction<FetchUserPayload>) {
  const username = action.payload.username;

  try {
    const user: User = yield call(fetcher, `/users?username=${username}`);
    yield put<PayloadAction<User>>({
      type: Actions.USER_FETCH_SUCCEEDED,
      payload: user,
    });

    localStorage.setItem(LocalStorageKeys.username, username);
  } catch (e) {
    const axiosError = e as AxiosError;

    if (axiosError.response && axiosError.response.status === 404) {
      yield put<PayloadAction<SetNewUserPopupVisibilityPayload>>({
        type: Actions.SET_SHOW_NEW_USER_POPUP,
        payload: {
          visibility: true,
          username,
        },
      });
    }
  }
}
