import { DateTime } from 'luxon';

export class DateHelper {
  static getNextProcessingTime = () => {
    const now = DateTime.now().toUTC();
    let stop = false;
    let nextHour = 0;

    for (let i = 0; i < 6 && !stop; i++) {
      const hour = 0 + i * 4;

      if (now.hour < hour) {
        nextHour = hour;
        stop = true;
      }
    }

    const isHourNextDay = !stop;
    const tommorow = now.plus({ days: 1 });

    const nextProcessingUtc = !isHourNextDay
      ? DateTime.utc(now.year, now.month, now.day, nextHour, 0, 0)
      : DateTime.utc(tommorow.year, tommorow.month, tommorow.day, 0, 0, 0);

    return nextProcessingUtc
      .toLocal()
      .toLocaleString({ ...DateTime.DATETIME_MED_WITH_SECONDS });
  };
}
