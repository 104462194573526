import axios from 'axios';

export const fetcher = async <T>(url: string) => {
  const apiRoot = process.env.REACT_APP_API_BASE_URL;

  const response = await axios.get<T>(apiRoot + url);

  return response.data;
};

export const post = async <T, D>(url: string, data: D) => {
  const apiRoot = process.env.REACT_APP_API_BASE_URL;

  const response = await axios.post<T>(apiRoot + url, data);

  return response.data;
};
