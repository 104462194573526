import { Action, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { User } from '../shared/models/user';
import { Actions } from './actions';
import { SetNewUserPopupVisibilityPayload } from './payloads/set-new-user-popup-visibility.payload';
import { State } from './state';

export const reducer: Reducer = (state: State, action: Action): State => {
  if (typeof state === 'undefined') {
    state = {
      username: '',
      showNewUserPopup: false,
    };
  }

  switch (action.type) {
    case Actions.USER_FETCH_SUCCEEDED:
      const user = (action as PayloadAction<User>).payload;

      return {
        ...state,
        username: user.username,
      };
    case Actions.SET_SHOW_NEW_USER_POPUP:
      const newUserPopupPayload = (
        action as PayloadAction<SetNewUserPopupVisibilityPayload>
      ).payload;

      return {
        ...state,
        username: !!newUserPopupPayload.username
          ? newUserPopupPayload.username
          : state.username,
        showNewUserPopup: newUserPopupPayload.visibility,
      };
    case Actions.SET_USERNAME:
      const username = (action as PayloadAction<string>).payload;

      return {
        ...state,
        username,
      };
    default:
      return state;
  }
};
