import { Grid } from '@mantine/core';
import { LeaderboardItem } from '../../models/leaderboard/leaderboard-item';

interface Props {
  title: string;
  data?: LeaderboardItem;
}

export const ItemTopOfPeriod = ({ title, data }: Props) => {
  return (
    <Grid>
      <Grid.Col span={4}>
        <span>{title}: </span>
      </Grid.Col>
      <Grid.Col span={4}>
        <span>{data?.scrobbles ?? 0} Scrobbles </span>
      </Grid.Col>
      <Grid.Col span={4}>
        <span>Rank: {data?.rank ?? 'N/A'}</span>
      </Grid.Col>
    </Grid>
  );
};
