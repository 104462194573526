import { MantineProvider } from '@mantine/core';
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { reducer } from './store/reducers';
import { fetchUserSaga } from './store/sagas/fetch-user.saga';
import { registerUserSaga } from './store/sagas/register-user.saga';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(fetchUserSaga);
sagaMiddleware.run(registerUserSaga);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MantineProvider theme={{ colorScheme: 'dark' }}>
        <Provider store={store}>
          <App />
        </Provider>
      </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type StoreDispatch = typeof store.dispatch;
