import { Box, Title } from '@mantine/core';
import styled from 'styled-components';
import { LeaderboardTable } from '../../../shared/components/leaderboard/leaderboard-table';
import { LeaderboardItem } from '../../../shared/models/leaderboard/leaderboard-item';

interface Props {
  title: string;
  data: LeaderboardItem[];
  onItemClicked?: (item: LeaderboardItem) => void;
}

const LeaderboardTableWrapper = styled.div`
  height: calc(100% - 25px);

  table {
    height: 100%;
  }
`;

export const ItemTop = ({ title, data, onItemClicked }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        height: 'calc(100% - 20px)',
        backgroundColor: theme.colors.dark[8],
        color: theme.colors.dark[0],
        borderRadius: 10,
        padding: 10,
      })}
    >
      <Title order={5} style={{ height: '25px' }}>
        {title}
      </Title>
      <LeaderboardTableWrapper>
        <LeaderboardTable
          data={data!}
          onItemClicked={onItemClicked}
        ></LeaderboardTable>
      </LeaderboardTableWrapper>
    </Box>
  );
};
