import { Title } from '@mantine/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useUsername } from '../hooks/use-username.hook';
import { StoreDispatch } from '../index';
import { DateHelper } from '../shared/helpers/date.helper';
import { Paragraph } from '../shared/components/paragraph';
import { Actions } from '../store/actions';

const Box = styled.div`
  background-color: #1d2026;
  width: 650px;
  padding: 10px;
  border-radius: 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.p`
  margin-bottom: 0px;
`;

const Input = styled.input`
  height: 25px;
  border: none;
  outline: none;
  justify-content: center;
  margin-bottom: 16px;
`;

const Button = styled.button`
  border: none;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
`;

export const NewUser = () => {
  const username = useUsername();
  const [timeZone, setTimeZone] = useState<string>('');

  const dispatch = useDispatch<StoreDispatch>();

  const submit = () => {
    dispatch({
      type: Actions.REGISTER_USER_REQUESTED,
      payload: { username, timeZone },
    });
  };

  return (
    <Box>
      <Title
        order={2}
        sx={(theme) => {
          return { color: theme.colors.dark[0] };
        }}
      >
        User not found
      </Title>
      <Section>
        <Paragraph>
          <Description>
            The user {username} does not currently exists in our database. Enter
            the time zone you would like to see the data in (leave empty for
            UTC). Ideally the same as the time zone option on this{' '}
            <a href="https://www.last.fm/settings/website" tabIndex={-1}>
              page
            </a>{' '}
            (only the Country/City part).
          </Description>
        </Paragraph>
        <Paragraph>
          <p>
            The next processing time is at {DateHelper.getNextProcessingTime()}{' '}
            (UTC).
          </p>
        </Paragraph>
        <Input
          placeholder="Timezone (Default is UTC)"
          tabIndex={0}
          onChange={(event) => {
            setTimeZone(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && username.length > 0) {
              submit();
            }
          }}
        ></Input>
        <Button tabIndex={1} onClick={submit}>
          Submit
        </Button>
      </Section>
    </Box>
  );
};
