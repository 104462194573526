import { List, useMantineTheme } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const MenuItem = styled(List.Item)`
  height: 37px;

  :not(:first-of-type) {
    margin-top: 0;
  }

  &:hover {
    background-color: #25262b;
  }
`;

const NavigationLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-left: 5px;

  display: inline-block;
  text-decoration: none;
  color: ${(props) => props.color};
`;

export const Navigation = () => {
  const theme = useMantineTheme();

  return (
    <List listStyleType="none" spacing="sm">
      <MenuItem>
        <NavigationLink to="/overview" color={theme.colors.dark[0]}>
          Overview
        </NavigationLink>
      </MenuItem>
      <MenuItem>
        <NavigationLink to="/leaderboard" color={theme.colors.dark[0]}>
          Leaderboard
        </NavigationLink>
      </MenuItem>
    </List>
  );
};
