import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { ItemPageHeader } from '../../shared/components/item/item-page-header';
import { fetcher } from '../../shared/fetcher';
import { LeaderboardItem } from '../../shared/models/leaderboard/leaderboard-item';
import { Stats } from '../../shared/models/stats';
import { ArtistProps } from './models/artist-props';

export const ArtistHeader = ({ artistName }: ArtistProps) => {
  const username = useUsername();
  const { data } = useSWRImmutable<Stats>(
    `/artists/${artistName}/stats?username=${username}`,
    fetcher,
  );

  const leaderboardItemChecker = (leaderboardItem: LeaderboardItem): boolean =>
    leaderboardItem.name === artistName;

  return (
    <>
      {data && (
        <ItemPageHeader
          name={artistName}
          data={data}
          type="artists"
          leaderboardItemChecker={leaderboardItemChecker}
        />
      )}
    </>
  );
};
