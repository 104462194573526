import { Table, useMantineTheme } from '@mantine/core';
import { useRef } from 'react';
import styled from 'styled-components';
import { LeaderboardItem } from '../../models/leaderboard/leaderboard-item';

const Leaderboard = styled(Table)`
  display: block;
  width: 100%;
  height: calc(100% - 40px);
`;

const Header = styled.thead`
  height: 40px;
`;

const Body = styled.tbody`
  display: block;
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
`;

const Row = styled.tr<{ hoverColor?: string }>`
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

interface Props {
  data: LeaderboardItem[];
  onItemClicked?: (item: LeaderboardItem) => void;
  onBottomReached?: () => void;
}

export const LeaderboardTable = ({
  data,
  onItemClicked,
  onBottomReached,
}: Props) => {
  const theme = useMantineTheme();
  const leaderboardBodyRef = useRef(null);

  const onScroll = () => {
    if (onBottomReached && leaderboardBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        leaderboardBodyRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onBottomReached();
      }
    }
  };

  const rows = data.map((item, index) => {
    return (
      <Row
        key={index}
        hoverColor={theme.colors.dark[7]}
        onClick={() => onItemClicked && onItemClicked(item)}
      >
        <td style={{ width: '10%' }}>{item.rank}</td>
        <td
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
          }}
        >
          {item.name}
        </td>
        <td>{item.scrobbles}</td>
      </Row>
    );
  });

  return (
    <Leaderboard>
      <Header>
        <Row>
          <th style={{ width: '10%' }}>Rank</th>
          <th>Name</th>
          <th>Scrobbles</th>
        </Row>
      </Header>
      <Body ref={leaderboardBodyRef} onScroll={onScroll}>
        {rows}
      </Body>
    </Leaderboard>
  );
};
