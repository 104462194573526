import { Grid, Group } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { AlbumHeader } from './album-header';
import { AlbumScrobblesTotals } from './album-scrobbles-totals';
import { AlbumTopSongs } from './album-top-songs';

export const Album = () => {
  const { artistName, albumName } = useParams();

  return (
    <>
      {artistName && albumName && (
        <Group
          direction="column"
          grow
          style={{ height: '100%', flexWrap: 'nowrap' }}
        >
          <AlbumHeader artistName={artistName} albumName={albumName} />
          <AlbumScrobblesTotals artistName={artistName} albumName={albumName} />
          <Grid style={{ height: '280px', flexGrow: 0 }}>
            <Grid.Col span={12} style={{ height: '100%' }}>
              <AlbumTopSongs artistName={artistName} albumName={albumName} />
            </Grid.Col>
          </Grid>
        </Group>
      )}
    </>
  );
};
