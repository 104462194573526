import { LoadingOverlay } from '@mantine/core';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { Suspense } from '../../shared/components/suspense';
import { fetcher } from '../../shared/fetcher';
import { Totals } from '../../shared/models/totals';
import { ScrobbleTotals } from '../overview/scrobbles/scrobble-totals';
import { ArtistProps } from './models/artist-props';

export const ArtistScrobblesTotals = ({ artistName }: ArtistProps) => {
  const username = useUsername();
  const { data } = useSWRImmutable<Totals[]>(
    `/artists/${artistName}/totals?username=${username}`,
    fetcher,
  );

  return (
    <div style={{ flex: '1 1 0px', position: 'relative', maxHeight: '450px' }}>
      <Suspense
        loading={!data}
        suspense={<LoadingOverlay visible />}
        content={<ScrobbleTotals totals={data!}></ScrobbleTotals>}
      ></Suspense>
    </div>
  );
};
