import { useState } from 'react';
import styled from 'styled-components';
import { LeaderboardTypeSelector } from '../../../shared/components/leaderboard/leaderboard-type-selector';
import { LeaderboardType } from '../../../shared/models/leaderboard/leaderboard-type';
import { AlbumsLeaderboard } from './albums-leaderboard';
import { ArtistsLeaderboard } from './artists-leaderboard';
import { SongsLeaderboard } from './songs-leaderboard';

const LeaderboardWrapper = styled.div`
  height: 100%;
`;

export const Leaderboard = () => {
  const [leaderboardType, setLeaderboardType] =
    useState<LeaderboardType>('artists');

  const getLeaderboard = () => {
    switch (leaderboardType) {
      case 'artists':
        return <ArtistsLeaderboard />;
      case 'albums':
        return <AlbumsLeaderboard />;
      case 'songs':
        return <SongsLeaderboard />;
    }
  };

  return (
    <LeaderboardWrapper>
      <LeaderboardTypeSelector
        value={leaderboardType}
        onChange={setLeaderboardType}
      />
      {getLeaderboard()}
    </LeaderboardWrapper>
  );
};
