import { DateTime } from 'luxon';
import { LineChartData } from '../models/charts/line-chart-data';
import { Totals } from '../models/totals';

export enum SortMode {
  Asc,
  Desc,
}

export class TotalsHelper {
  static sortTotalsByDate = (
    totals: Totals[],
    sortMode: SortMode = SortMode.Asc,
  ) =>
    totals.sort((first, second) => {
      const firstDateTime = DateTime.fromISO(first.date).startOf('day');
      const secondDateTime = DateTime.fromISO(second.date).startOf('day');

      return firstDateTime <= secondDateTime
        ? sortMode === SortMode.Asc
          ? -1
          : 1
        : sortMode === SortMode.Asc
        ? 1
        : -1;
    });

  static mapTotalsToLineChartData = (totals: Totals): LineChartData => ({
    name: totals.date,
    value: totals.scrobbles,
  });

  static filterTotalsToDateRange = (
    totals: Totals,
    from: Date | null,
    to: Date | null,
  ) => {
    const totalsDate = DateTime.fromISO(totals.date);
    const fromISO = from ? DateTime.fromJSDate(from) : null;
    const toISO = to ? DateTime.fromJSDate(to) : null;

    if (fromISO && toISO) {
      return totalsDate >= fromISO && totalsDate <= toISO;
    }
    if (fromISO) {
      return totalsDate >= fromISO;
    }
    if (toISO) {
      return totalsDate <= toISO;
    }

    return true;
  };

  static getScrobblesSumForPeriodOfTime = (
    totals: Totals[],
    from: Date,
    to: Date,
  ): number =>
    totals
      .filter((total) => TotalsHelper.filterTotalsToDateRange(total, from, to))
      .reduce((sum, total) => (sum += total.scrobbles), 0);
}
