import { Text as TextWrapper } from '@mantine/core';

interface Props {
  text: string;
}

export const Text = ({ text }: Props) => {
  return (
    <TextWrapper sx={(theme) => ({ color: theme.colors.dark[0] })}>
      {text}
    </TextWrapper>
  );
};
