import { Group, Skeleton, Title } from '@mantine/core';
import { LeaderboardItem } from '../../models/leaderboard/leaderboard-item';
import { LeaderboardType } from '../../models/leaderboard/leaderboard-type';
import { Stats } from '../../models/stats';
import { Suspense } from '../suspense';
import { ItemTopOfPeriods } from './item-top-of-periods';

interface ItemPageHeaderProps {
  name: string;
  data: Stats;
  type: LeaderboardType;
  leaderboardItemChecker: (leaderboardItem: LeaderboardItem) => boolean;
}

export const ItemPageHeader = ({
  name,
  data,
  type,
  leaderboardItemChecker,
}: ItemPageHeaderProps) => {
  return (
    <div>
      <Suspense
        loading={!data}
        suspense={<Skeleton height={23}></Skeleton>}
        content={
          <Group position="apart">
            <div>
              <Title
                order={2}
                sx={(theme) => {
                  return { color: theme.colors.dark[0], paddingBottom: 16 };
                }}
              >
                {name}
              </Title>
              <Title
                order={3}
                sx={(theme) => {
                  return { color: theme.colors.dark[0] };
                }}
              >
                Scrobbles: {data?.scrobbles} - Rank: {data?.rank}
              </Title>
            </div>
            <ItemTopOfPeriods
              type={type}
              leaderboardItemChecker={leaderboardItemChecker}
            />
          </Group>
        }
      ></Suspense>
    </div>
  );
};
