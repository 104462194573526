import { LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { ItemTop } from '../../shared/components/item/item-top';
import { Suspense } from '../../shared/components/suspense';
import { fetcher } from '../../shared/fetcher';
import { LeaderboardItem } from '../../shared/models/leaderboard/leaderboard-item';

import { AlbumProps } from './models/album-props';

export const AlbumTopSongs = ({ artistName, albumName }: AlbumProps) => {
  const username = useUsername();
  const navigate = useNavigate();
  const { data } = useSWRImmutable<LeaderboardItem[]>(
    `/artists/${artistName}/albums/${albumName}/top-songs?username=${username}`,
    fetcher,
  );

  const onItemClicked = (leaderboardItem: LeaderboardItem) => {
    navigate(
      `/artist/${artistName}/album/${leaderboardItem.albumName}/song/${leaderboardItem.name}`,
    );
  };

  return (
    <Suspense
      loading={!data}
      suspense={<LoadingOverlay visible />}
      content={
        <ItemTop title="Top Songs" data={data!} onItemClicked={onItemClicked} />
      }
    ></Suspense>
  );
};
