import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { ItemPageHeader } from '../../shared/components/item/item-page-header';
import { fetcher } from '../../shared/fetcher';
import { LeaderboardItem } from '../../shared/models/leaderboard/leaderboard-item';
import { Stats } from '../../shared/models/stats';
import { AlbumProps } from './models/album-props';

export const AlbumHeader = ({ artistName, albumName }: AlbumProps) => {
  const username = useUsername();
  const { data } = useSWRImmutable<Stats>(
    `/artists/${artistName}/albums/${albumName}/stats?username=${username}`,
    fetcher,
  );

  const leaderboardItemChecker = (leaderboardItem: LeaderboardItem): boolean =>
    leaderboardItem.name === albumName &&
    leaderboardItem.artistName === artistName;

  return (
    <>
      {data && (
        <ItemPageHeader
          name={`${artistName} - ${albumName}`}
          data={data}
          type="albums"
          leaderboardItemChecker={leaderboardItemChecker}
        />
      )}
    </>
  );
};
