import { AppShell, Header, Navbar, Title } from '@mantine/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import { Dashboard } from './dashboard/dashboard';
import { Header as MyHeader } from './header/header';
import { Navigation } from './navigation/navigation';
import { useUsername } from './hooks/use-username.hook';
import { StoreDispatch } from './index';
import { NewUserPopup } from './new-user/new-user-popup';
import { LocalStorageKeys } from './shared/models/constants';
import { Actions } from './store/actions';

function App() {
  const username = useUsername();
  const dispatch = useDispatch<StoreDispatch>();

  useEffect(() => {
    const usernameFromLocalStorage = localStorage.getItem(
      LocalStorageKeys.username,
    );

    if (!!usernameFromLocalStorage) {
      dispatch({
        type: Actions.SET_USERNAME,
        payload: usernameFromLocalStorage,
      });
    }
  }, []);

  return (
    <AppShell
      header={
        <Header height={75} p="xs">
          {' '}
          <MyHeader></MyHeader>
        </Header>
      }
      navbar={
        <Navbar width={{ base: 200 }}>
          <Navigation />
        </Navbar>
      }
      styles={(theme) => {
        return {
          root: { display: 'flex', flexDirection: 'column', height: '100%' },
          body: { flex: 1, height: 'calc(100% - 75px)' },
          main: {
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.colors.dark[6],
          },
        };
      }}
    >
      <section style={{ flex: 1, height: '100%' }}>
        {!!username ? (
          <Dashboard></Dashboard>
        ) : (
          <Title
            order={2}
            sx={(theme) => {
              return { color: theme.colors.dark[0] };
            }}
          >
            Search for a user to see their data!
          </Title>
        )}
      </section>
      <NewUserPopup></NewUserPopup>
    </AppShell>
  );
}

export default App;
