import { TooltipProps } from 'recharts';

export const ScrobbleChartTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#1d2026', padding: '8px' }}>
        <div className="label">{`${label}`}</div>
        <div className="label">{`${payload[0].value} Scrobbles`}</div>
      </div>
    );
  }

  return null;
};
