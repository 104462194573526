import { LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../../../hooks/use-username.hook';
import { Suspense } from '../../../../shared/components/suspense';
import { fetcher } from '../../../../shared/fetcher';
import { LeaderboardTable } from '../../../../shared/components/leaderboard/leaderboard-table';
import { LeaderboardItem } from '../../../../shared/models/leaderboard/leaderboard-item';
import { LeaderboardType } from '../../../../shared/models/leaderboard/leaderboard-type';
import { useNavigate } from 'react-router-dom';

interface Props {
  type: LeaderboardType;
  limit?: number;
  from?: string;
  to?: string;
}

export const LeaderboardContainer = ({ type, limit, from, to }: Props) => {
  const navigate = useNavigate();
  const username = useUsername();

  const [skip, setSkip] = useState(0);
  const [leaderboard, setLeaderboard] = useState<LeaderboardItem[]>([]);

  const leaderboardLimit = limit ?? 30;
  let queryString = `?username=${username}&type=${type}&limit=${leaderboardLimit}&skip=${skip}`;

  if (from) {
    queryString = `${queryString}&from=${from}`;
  }

  if (to) {
    queryString = `${queryString}&to=${to}`;
  }

  const { data } = useSWRImmutable<LeaderboardItem[]>(
    `/leaderboard${queryString}`,
    fetcher,
  );

  useEffect(() => {
    if (data) {
      setLeaderboard([...leaderboard, ...data]);
    }
  }, [data]);

  const onBottomReached = () => {
    setSkip(skip + leaderboardLimit);
  };

  const onItemClicked = (item: LeaderboardItem) => {
    if (type === 'artists') {
      navigate(`/artist/${item.name}`);
    } else if (type === 'albums') {
      navigate(`/artist/${item.artistName}/album/${item.name}`);
    } else if (type === 'songs') {
      navigate(
        `/artist/${item.artistName}/album/${item.albumName}/song/${item.name}`,
      );
    }
  };

  return (
    <Suspense
      loading={!leaderboard}
      suspense={<LoadingOverlay visible></LoadingOverlay>}
      content={
        <LeaderboardTable
          data={leaderboard!}
          onItemClicked={onItemClicked}
          onBottomReached={onBottomReached}
        ></LeaderboardTable>
      }
    ></Suspense>
  );
};
