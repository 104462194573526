import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { post } from '../../shared/fetcher';
import { LocalStorageKeys } from '../../shared/models/constants';
import { Actions } from '../actions';
import { FetchUserPayload } from '../payloads/fetch-user.payload';
import { SetNewUserPopupVisibilityPayload } from '../payloads/set-new-user-popup-visibility.payload';

export function* registerUserSaga() {
  yield takeLatest(Actions.REGISTER_USER_REQUESTED, registerUser);
}

function* registerUser(action: PayloadAction<FetchUserPayload>) {
  const payload = action.payload;

  try {
    yield call(post, `/users`, payload);
    yield put<PayloadAction<SetNewUserPopupVisibilityPayload>>({
      type: Actions.SET_SHOW_NEW_USER_POPUP,
      payload: { visibility: false },
    });

    localStorage.setItem(LocalStorageKeys.username, payload.username);
  } catch (e) {
    console.log(e);
  }
}
