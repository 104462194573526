import { Grid, Group } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { ArtistHeader } from './artist-header';
import { ArtistScrobblesTotals } from './artist-scrobbles-totals';
import { ArtistTopAlbums } from './artist-top-albums';
import { ArtistTopSongs } from './artist-top-songs';

export const Artist = () => {
  const { artistName } = useParams();

  return (
    <>
      {artistName && (
        <Group
          direction="column"
          grow
          style={{ height: '100%', flexWrap: 'nowrap' }}
        >
          <ArtistHeader artistName={artistName} />
          <ArtistScrobblesTotals artistName={artistName} />
          <Grid style={{ height: '280px', flexGrow: 0 }}>
            <Grid.Col span={6} style={{ height: '100%' }}>
              <ArtistTopAlbums artistName={artistName} />
            </Grid.Col>
            <Grid.Col span={6} style={{ height: '100%' }}>
              <ArtistTopSongs artistName={artistName} />
            </Grid.Col>
          </Grid>
        </Group>
      )}
    </>
  );
};
