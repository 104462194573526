import { Text as TextWrapper } from '@mantine/core';
import { PropsWithChildren } from 'react';

export const Paragraph = ({ children }: PropsWithChildren<{}>) => {
  return (
    <TextWrapper sx={(theme) => ({ color: theme.colors.dark[0] })}>
      {children}
    </TextWrapper>
  );
};
