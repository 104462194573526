import { Box, Group, LoadingOverlay, Select, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../../../../hooks/use-username.hook';
import { LeaderboardTypeSelector } from '../../../../../shared/components/leaderboard/leaderboard-type-selector';
import { Suspense } from '../../../../../shared/components/suspense';
import { fetcher } from '../../../../../shared/fetcher';
import { LeaderboardType } from '../../../../../shared/models/leaderboard/leaderboard-type';
import { LeaderboardTable } from '../../../../../shared/components/leaderboard/leaderboard-table';
import { LeaderboardItem } from '../../../../../shared/models/leaderboard/leaderboard-item';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  from: string;
  to: string;
}

const numberOfItemsSelection = [5, 10];

export const TopOfPeriod = ({ title, from, to }: Props) => {
  const navigate = useNavigate();
  const username = useUsername();

  const [leaderboardType, setLeaderboardType] =
    useState<LeaderboardType>('artists');
  const [numberOfItems, setNumberOfItems] = useState<number>(
    numberOfItemsSelection[0],
  );
  const [leaderboard, setLeaderboard] = useState<LeaderboardItem[]>([]);

  const { data } = useSWRImmutable<LeaderboardItem[]>(
    `/leaderboard?username=${username}&type=${leaderboardType}&limit=${numberOfItems}&from=${from}&to=${to}`,
    fetcher,
  );

  useEffect(() => {
    if (data) {
      setLeaderboard(data);
    }
  }, [data]);

  const onItemClicked = (item: LeaderboardItem) => {
    if (leaderboardType === 'artists') {
      navigate(`/artist/${item.name}`);
    } else if (leaderboardType === 'albums') {
      navigate(`/artist/${item.artistName}/album/${item.name}`);
    } else if (leaderboardType === 'songs') {
      navigate(
        `/artist/${item.artistName}/album/${item.albumName}/song/${item.name}`,
      );
    }
  };

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.colors.dark[8],
        borderRadius: 10,
        padding: 10,
        color: theme.colors.dark[0],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 270,
      })}
    >
      <Group align="flex-start" position="apart" style={{ height: 40 }}>
        <Title order={5}>{title}</Title>
        <Group>
          <LeaderboardTypeSelector
            value={leaderboardType}
            onChange={setLeaderboardType}
          />
          <Select
            value={`${numberOfItems}`}
            size="xs"
            style={{ width: '50px' }}
            data={numberOfItemsSelection.map((numberOfItem) => ({
              label: `${numberOfItem}`,
              value: `${numberOfItem}`,
            }))}
            onChange={(value) =>
              value && setNumberOfItems(Number.parseInt(value, 10))
            }
          ></Select>
        </Group>
      </Group>
      <Suspense
        loading={!leaderboard}
        suspense={<LoadingOverlay visible></LoadingOverlay>}
        content={
          <LeaderboardTable
            data={leaderboard!}
            onItemClicked={onItemClicked}
          ></LeaderboardTable>
        }
      ></Suspense>
    </Box>
  );
};
