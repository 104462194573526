import { LoadingOverlay } from '@mantine/core';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { Suspense } from '../../shared/components/suspense';
import { fetcher } from '../../shared/fetcher';
import { Totals } from '../../shared/models/totals';
import { ScrobbleTotals } from '../overview/scrobbles/scrobble-totals';
import { SongProps } from './models/song-props';

export const SongScrobblesTotals = ({
  artistName,
  albumName,
  songName,
}: SongProps) => {
  const username = useUsername();
  const { data } = useSWRImmutable<Totals[]>(
    `/artists/${artistName}/albums/${albumName}/songs/${songName}/totals?username=${username}`,
    fetcher,
  );

  return (
    <div
      style={{
        flex: '1 1 0px',
        position: 'relative',
        maxHeight: 'calc(100% - 90px)',
      }}
    >
      <Suspense
        loading={!data}
        suspense={<LoadingOverlay visible />}
        content={<ScrobbleTotals totals={data!}></ScrobbleTotals>}
      ></Suspense>
    </div>
  );
};
