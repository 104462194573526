import { Grid } from '@mantine/core';
import { DateTime } from 'luxon';
import { TopOfPeriod } from './top-of-period/top-of-period';

export const TopOfPeriods = () => {
  const startOfWeek = DateTime.now().startOf('week').toISODate();
  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const startOfYear = DateTime.now().startOf('year').toISODate();

  const endOfWeek = DateTime.now().endOf('week').toISODate();
  const endOfMonth = DateTime.now().endOf('month').toISODate();
  const endOfYear = DateTime.now().endOf('year').toISODate();

  return (
    <Grid>
      <Grid.Col span={4}>
        <TopOfPeriod
          title="This Week"
          from={startOfWeek}
          to={endOfWeek}
        ></TopOfPeriod>
      </Grid.Col>
      <Grid.Col span={4}>
        <TopOfPeriod
          title="This Month"
          from={startOfMonth}
          to={endOfMonth}
        ></TopOfPeriod>
      </Grid.Col>
      <Grid.Col span={4}>
        <TopOfPeriod
          title="This Year"
          from={startOfYear}
          to={endOfYear}
        ></TopOfPeriod>
      </Grid.Col>
    </Grid>
  );
};
