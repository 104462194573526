import { Button, Grid, Group, Popover } from '@mantine/core';
import { RangeCalendar } from '@mantine/dates';
import { DateTime } from 'luxon';
import { useState } from 'react';

interface Props {
  onRangeChange: (range: [Date | null, Date | null]) => void;
}

export const PeriodPicker = (props: Props) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [text, setText] = useState<string>('All Time');

  const onRangeChange = ([from, to]: [Date, Date]) => {
    if (from && to) {
      setText(
        `${DateTime.fromJSDate(from).toISODate()} - ${DateTime.fromJSDate(
          to,
        ).toISODate()}`,
      );

      props.onRangeChange([from, to]);
      setOpened(false);
    }

    setValue([from, to]);
  };

  const usePreset = (text: string, start: Date | null, end: Date | null) => {
    setText(text);
    setValue([start, end]);
    props.onRangeChange([start, end]);
    setOpened(false);
  };

  return (
    <div>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        target={<Button onClick={() => setOpened((o) => !o)}>{text}</Button>}
      >
        <Group direction="row">
          <RangeCalendar
            value={value}
            onChange={onRangeChange}
            sx={(theme) => ({ backgroundColor: theme.colors.dark[6] })}
            maxDate={new Date()}
          ></RangeCalendar>
          <Grid
            style={{ alignSelf: 'flex-end', maxWidth: 260 }}
            columns={12}
            gutter={7}
            grow={true}
          >
            <Grid.Col span={6}>
              <PresetButton
                text="This Week"
                startDate={DateTime.now().startOf('week').toJSDate()}
                endDate={DateTime.now().toJSDate()}
                usePreset={usePreset}
                isLast={false}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={6}>
              <PresetButton
                text="Last Week"
                startDate={DateTime.now()
                  .minus({ week: 1 })
                  .startOf('week')
                  .toJSDate()}
                endDate={DateTime.now()
                  .minus({ week: 1 })
                  .endOf('week')
                  .toJSDate()}
                usePreset={usePreset}
                isLast={false}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={6}>
              <PresetButton
                text="This Month"
                startDate={DateTime.now().startOf('month').toJSDate()}
                endDate={DateTime.now().toJSDate()}
                usePreset={usePreset}
                isLast={true}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={6}>
              <PresetButton
                text="Last Month"
                startDate={DateTime.now()
                  .minus({ month: 1 })
                  .startOf('month')
                  .toJSDate()}
                endDate={DateTime.now()
                  .minus({ month: 1 })
                  .endOf('month')
                  .toJSDate()}
                usePreset={usePreset}
                isLast={false}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={6}>
              <PresetButton
                text="This Year"
                startDate={DateTime.now().startOf('year').toJSDate()}
                endDate={DateTime.now().toJSDate()}
                usePreset={usePreset}
                isLast={false}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={6}>
              <PresetButton
                text="Last Year"
                startDate={DateTime.now()
                  .minus({ year: 1 })
                  .startOf('year')
                  .toJSDate()}
                endDate={DateTime.now()
                  .minus({ year: 1 })
                  .endOf('year')
                  .toJSDate()}
                usePreset={usePreset}
                isLast={true}
              ></PresetButton>
            </Grid.Col>
            <Grid.Col span={12}>
              <PresetButton
                text="All Time"
                startDate={null}
                endDate={null}
                usePreset={usePreset}
                isLast={true}
              ></PresetButton>
            </Grid.Col>
          </Grid>
        </Group>
      </Popover>
    </div>
  );
};

interface PresetButtonProps {
  text: string;
  startDate: Date | null;
  endDate: Date | null;
  isLast: boolean;
  usePreset: (text: string, start: Date | null, end: Date | null) => void;
}

const PresetButton = ({
  text,
  startDate,
  endDate,
  usePreset,
  isLast,
}: PresetButtonProps) => {
  return (
    <Button
      style={{ width: '100%', marginBottom: isLast ? 0 : 8 }}
      onClick={() => {
        usePreset(text, startDate, endDate);
      }}
    >
      {text}
    </Button>
  );
};
