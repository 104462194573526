import { Center, Group, Title } from '@mantine/core';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../hooks/use-username.hook';
import { fetcher } from '../shared/fetcher';
import { UserInformation } from '../shared/models/user-information';
import { HeaderUserSearch } from './header-user-search/header-user-search';
import { UserDisplay } from './user-display/user-display';

export const Header = () => {
  const username = useUsername();
  const { data: userInformation } = useSWRImmutable<UserInformation>(
    !!username ? `/users/information?username=${username}` : null,
    fetcher,
  );

  return (
    <Group style={{ height: '100%' }}>
      <Title
        order={1}
        style={{ flex: '1 1 0px' }}
        sx={(theme) => {
          return { color: theme.colors.dark[0] };
        }}
      >
        Last Dashboard
      </Title>
      <Center style={{ flex: '1 1 0px' }}>
        <HeaderUserSearch />
      </Center>
      <Center style={{ flex: '1 1 0px', justifyContent: 'end' }}>
        {userInformation ? (
          <UserDisplay userInformation={userInformation}></UserDisplay>
        ) : (
          <div></div>
        )}
      </Center>
    </Group>
  );
};
