import { useEffect, useState } from 'react';
import { ScrobbleLineChart } from '../../../../shared/components/scrobble-line-chart';
import { TotalsHelper } from '../../../../shared/helpers/totals.helper';
import { LineChartData } from '../../../../shared/models/charts/line-chart-data';
import { Totals } from '../../../../shared/models/totals';

interface Props {
  totals: Totals[];
  from: Date | null;
  to: Date | null;
}

export const ScrobbleTotalsStackedChart = ({ totals, from, to }: Props) => {
  const [data, setData] = useState<LineChartData[]>([]);

  useEffect(() => {
    const chartData: LineChartData[] = TotalsHelper.sortTotalsByDate(totals)
      .reduce(
        (acc, total) => {
          const sum = acc[acc.length - 1].scrobbles + total.scrobbles;
          const toAdd: Totals = {
            date: total.date,
            scrobbles: sum,
          };
          return [...acc, toAdd];
        },
        [{ date: totals[0].date, scrobbles: 0 }],
      )
      .filter((item) => TotalsHelper.filterTotalsToDateRange(item, from, to))
      .map(TotalsHelper.mapTotalsToLineChartData);

    setData(chartData);
  }, [totals, from, to]);

  return <ScrobbleLineChart data={data}></ScrobbleLineChart>;
};
