import { SegmentedControl } from '@mantine/core';
import { LeaderboardType } from '../../models/leaderboard/leaderboard-type';

interface LeaderboardTypeSelection {
  label: string;
  value: LeaderboardType;
}

const leaderboardTypes: LeaderboardTypeSelection[] = [
  {
    label: 'Artists',
    value: 'artists',
  },
  {
    label: 'Albums',
    value: 'albums',
  },
  {
    label: 'Songs',
    value: 'songs',
  },
];

interface Props {
  value: LeaderboardType;
  onChange: (value: LeaderboardType) => void;
}

export const LeaderboardTypeSelector = ({ value, onChange }: Props) => {
  return (
    <SegmentedControl
      value={value}
      data={leaderboardTypes}
      onChange={(value: LeaderboardType) => {
        onChange(value);
      }}
    ></SegmentedControl>
  );
};
