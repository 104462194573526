import { Button, Group, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StoreDispatch } from '../../index';
import { Actions } from '../../store/actions';

export const HeaderUserSearch = () => {
  const [username, setUsername] = useState<string>('');
  const dispatch = useDispatch<StoreDispatch>();

  const searchUser = () => {
    setUsername('');
    dispatch({ type: Actions.USER_FETCH_REQUESTED, payload: { username } });
  };

  return (
    <Group>
      <TextInput
        title="username"
        value={username}
        placeholder="Search for user"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && username.length > 0) {
            searchUser();
          }
        }}
        style={{ width: '240px' }}
      />
      <Button
        onClick={() => {
          searchUser();
        }}
        disabled={username.length === 0}
      >
        Go
      </Button>
    </Group>
  );
};
