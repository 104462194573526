import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { ItemPageHeader } from '../../shared/components/item/item-page-header';
import { fetcher } from '../../shared/fetcher';
import { LeaderboardItem } from '../../shared/models/leaderboard/leaderboard-item';
import { Stats } from '../../shared/models/stats';
import { SongProps } from './models/song-props';

export const SongHeader = ({ artistName, albumName, songName }: SongProps) => {
  const username = useUsername();
  const { data } = useSWRImmutable<Stats>(
    `/artists/${artistName}/albums/${albumName}/songs/${songName}/stats?username=${username}`,
    fetcher,
  );

  const leaderboardItemChecker = (leaderboardItem: LeaderboardItem): boolean =>
    leaderboardItem.artistName === artistName &&
    leaderboardItem.albumName === albumName &&
    leaderboardItem.name === songName;

  return (
    <>
      {data && (
        <ItemPageHeader
          name={`${artistName} - ${albumName} - ${songName}`}
          data={data}
          type="songs"
          leaderboardItemChecker={leaderboardItemChecker}
        />
      )}
    </>
  );
};
