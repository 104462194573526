import { useSelector } from 'react-redux';
import { Popup } from 'reactjs-popup';
import styled from 'styled-components';
import { State } from '../store/state';
import { NewUser } from './new-user';

const StyledPopup = styled(Popup)`
  &-content {
    border: none;
    background: none;
    width: initial;
  }
`;

export const NewUserPopup = () => {
  const showPopup = useSelector<State, boolean>(
    (state) => state.showNewUserPopup,
  );

  return (
    <StyledPopup
      open={showPopup}
      modal
      closeOnEscape={false}
      closeOnDocumentClick={false}
    >
      <NewUser />
    </StyledPopup>
  );
};
