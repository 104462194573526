import { DateTime } from 'luxon';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { LineChartData } from '../models/charts/line-chart-data';
import { ScrobbleChartTooltip } from './scrobble-chart-tooltip';

interface Props {
  data: LineChartData[];
}

export const ScrobbleLineChart = ({ data }: Props) => {
  const sorted = [...data].sort((first, second) => second.value - first.value);
  const max = sorted.length ? sorted[0].value : 0;
  const length = max.toString().length;
  const baseString = `1${'0'.repeat(length - 1)}`;
  const base = Number.parseInt(baseString, 10);
  const yTicks = [];
  for (let i = base; i < max; i += base) {
    yTicks.push(i);
  }

  const diff = data.length
    ? DateTime.fromISO(data[data.length - 1].name).diff(
        DateTime.fromISO(data[0].name),
        ['years', 'months'],
      )
    : null;

  const xTicks = data
    .filter((dataPoint, index) => {
      if (index === 0 || index === data.length - 1) {
        return true;
      }

      const unitOfTime = diff?.years && diff.years > 0 ? 'year' : 'month';
      const date = DateTime.fromISO(dataPoint.name).startOf(unitOfTime);

      return date.diff(DateTime.fromISO(dataPoint.name), 'days').days === 0;
    })
    .map((dataPoint) => dataPoint.name);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          right: 8,
        }}
      >
        <XAxis dataKey="name" type="category" ticks={xTicks} />
        <YAxis
          type="number"
          domain={['dataMin', 'dataMax']}
          ticks={[0, ...yTicks, max]}
        />
        <Tooltip content={<ScrobbleChartTooltip />} />
        <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};
