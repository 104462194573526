import { LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { ItemTop } from '../../shared/components/item/item-top';
import { Suspense } from '../../shared/components/suspense';
import { fetcher } from '../../shared/fetcher';
import { LeaderboardItem } from '../../shared/models/leaderboard/leaderboard-item';
import { ArtistProps } from './models/artist-props';

export const ArtistTopAlbums = ({ artistName }: ArtistProps) => {
  const username = useUsername();
  const navigate = useNavigate();
  const { data } = useSWRImmutable<LeaderboardItem[]>(
    `/artists/${artistName}/top-albums?username=${username}`,
    fetcher,
  );

  const onItemClicked = (leaderboardItem: LeaderboardItem) => {
    navigate(`/artist/${artistName}/album/${leaderboardItem.name}`);
  };

  return (
    <Suspense
      loading={!data}
      suspense={<LoadingOverlay visible />}
      content={
        <ItemTop
          title="Top Albums"
          data={data!}
          onItemClicked={onItemClicked}
        />
      }
    ></Suspense>
  );
};
