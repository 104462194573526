import { Center, Group, Title } from '@mantine/core';
import { useState } from 'react';
import { PeriodPicker } from '../../../shared/components/period-picker';
import { Switch } from '../../../shared/components/switch';
import { Totals } from '../../../shared/models/totals';
import { ScrobbleTotalsStackedChart } from './charts/scrobble-totals-stacked-chart';
import { ScrobbleTotalsUnstackedChart } from './charts/scrobble-totals-unstacked-chart';

interface Props {
  totals: Totals[];
}

export const ScrobbleTotals = ({ totals }: Props) => {
  const [chartMode, setChartMode] = useState<string>('Stacked');
  const [[from, to], setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  return (
    <Group
      direction="column"
      grow
      sx={(theme) => ({
        height: '100%',
        padding: 10,
        backgroundColor: theme.colors.dark[8],
        borderRadius: 10,
        color: theme.colors.dark[0],
      })}
    >
      <div style={{ maxHeight: 80, marginBottom: 10 }}>
        <Title order={2} style={{ flexGrow: 0, marginBottom: 16 }}>
          Scrobbles Over Time
        </Title>
        <Center
          style={{
            flexGrow: 0,
            justifyContent: 'space-between',
          }}
        >
          <PeriodPicker onRangeChange={setDateRange}></PeriodPicker>
          <Switch
            options={['Stacked', 'Unstacked']}
            selected={chartMode}
            setSelected={setChartMode}
          ></Switch>
        </Center>
      </div>
      <div style={{ maxHeight: 'calc(100% - 90px)' }}>
        {chartMode === 'Stacked' ? (
          <ScrobbleTotalsStackedChart
            totals={totals}
            from={from}
            to={to}
          ></ScrobbleTotalsStackedChart>
        ) : (
          <ScrobbleTotalsUnstackedChart
            totals={totals}
            from={from}
            to={to}
          ></ScrobbleTotalsUnstackedChart>
        )}
      </div>
    </Group>
  );
};
