import { DateTime } from 'luxon';
import { useUsername } from '../../../hooks/use-username.hook';
import { LeaderboardItem } from '../../models/leaderboard/leaderboard-item';
import { LeaderboardType } from '../../models/leaderboard/leaderboard-type';
import useSWRImmutable from 'swr/immutable';
import { fetcher } from '../../fetcher';
import { Box } from '@mantine/core';
import { ItemTopOfPeriod } from './item-top-of-period';

interface ItemTopOfPeriodsProps {
  type: LeaderboardType;
  leaderboardItemChecker: (leaderboardItem: LeaderboardItem) => boolean;
}

export const ItemTopOfPeriods = ({
  type,
  leaderboardItemChecker,
}: ItemTopOfPeriodsProps) => {
  const username = useUsername();
  const startOfWeek = DateTime.now().startOf('week').toISODate();
  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const startOfYear = DateTime.now().startOf('year').toISODate();

  const endOfWeek = DateTime.now().endOf('week').toISODate();
  const endOfMonth = DateTime.now().endOf('month').toISODate();
  const endOfYear = DateTime.now().endOf('year').toISODate();

  const { data: thisWeekLeaderboard } = useSWRImmutable<LeaderboardItem[]>(
    `/leaderboard?username=${username}&type=${type}&limit=0&from=${startOfWeek}&to=${endOfWeek}`,
    fetcher,
  );

  const { data: thisMonthLeaderboard } = useSWRImmutable<LeaderboardItem[]>(
    `/leaderboard?username=${username}&type=${type}&limit=0&from=${startOfMonth}&to=${endOfMonth}`,
    fetcher,
  );

  const { data: thisYearLeaderboard } = useSWRImmutable<LeaderboardItem[]>(
    `/leaderboard?username=${username}&type=${type}&limit=0&from=${startOfYear}&to=${endOfYear}`,
    fetcher,
  );

  const thisWeekData = thisWeekLeaderboard?.find(leaderboardItemChecker);

  const thisMonthData = thisMonthLeaderboard?.find(leaderboardItemChecker);

  const thisYearData = thisYearLeaderboard?.find(leaderboardItemChecker);

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.colors.dark[8],
        borderRadius: 10,
        padding: 10,
        color: theme.colors.dark[0],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '350px',
        minHeight: '20px',
      })}
    >
      <ItemTopOfPeriod title="This Week" data={thisWeekData} />
      <ItemTopOfPeriod title="This Month" data={thisMonthData} />
      <ItemTopOfPeriod title="This Year" data={thisYearData} />
    </Box>
  );
};
