import { Group, Grid } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { AlbumHeader } from '../album/album-header';
import { AlbumScrobblesTotals } from '../album/album-scrobbles-totals';
import { AlbumTopSongs } from '../album/album-top-songs';
import { SongHeader } from './song-header';
import { SongScrobblesTotals } from './song-scrobbles-totals';

export const Song = () => {
  const { artistName, albumName, songName } = useParams();

  return (
    <>
      {artistName && albumName && songName && (
        <Group
          direction="column"
          grow
          style={{ height: '100%', flexWrap: 'nowrap' }}
        >
          <SongHeader
            artistName={artistName}
            albumName={albumName}
            songName={songName}
          />
          <SongScrobblesTotals
            artistName={artistName}
            albumName={albumName}
            songName={songName}
          />
        </Group>
      )}
    </>
  );
};
