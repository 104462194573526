import { Text } from '../../shared/components/text';
import styled from 'styled-components';
import { UserInformation } from '../../shared/models/user-information';

const StyledUsedDisplay = styled.div`
  display: flex;
  align-items: center;
`;

const UserIcon = styled.img`
  border-radius: 50%;
  margin-left: 8px;
  width: 34px;
`;

interface Props {
  userInformation: UserInformation;
}

export const UserDisplay = ({ userInformation }: Props) => {
  const userIcon = userInformation.images.find(
    (image) => image.size === 'small',
  );

  const userIconSrc =
    !!userIcon && userIcon.url.length !== 0
      ? userIcon.url
      : 'https://lastfm.freetls.fastly.net/i/u/avatar170s/818148bf682d429dc215c1705eb27b98.png';

  return (
    <StyledUsedDisplay>
      <Text text={userInformation.username}></Text>
      <a href={userInformation.pageUrl}>
        <UserIcon src={userIconSrc} />
      </a>
    </StyledUsedDisplay>
  );
};
