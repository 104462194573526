import { Navigate, Route, Routes } from 'react-router-dom';
import { Overview } from './overview/overview';
import { Leaderboard } from './library/leaderboard/leaderboard';
import { Artist } from './artist/artist';
import { Album } from './album/album';
import { Song } from './song/song';

export const Dashboard = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/overview" />} />
    <Route path="/overview" element={<Overview />} />
    <Route path="/leaderboard" element={<Leaderboard />} />
    <Route path="/artist/:artistName" element={<Artist />} />
    <Route path="/artist/:artistName/album/:albumName" element={<Album />} />
    <Route
      path="/artist/:artistName/album/:albumName/song/:songName"
      element={<Song />}
    />
  </Routes>
);
