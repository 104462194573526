const commonStyle: React.CSSProperties = {
  transition: 'all 1s ease',
  display: 'flex',
  width: 'fit-content',
  padding: '8px',
};

const selectedStyle: React.CSSProperties = {
  ...commonStyle,
};

const unselectedStyle: React.CSSProperties = {
  ...commonStyle,
  cursor: 'pointer',
};

const selectedBgColor = '#49515f';
const unselectedBgColor = '#1d2026';

interface Props {
  options: [string, string];
  selected: string;
  setSelected: (value: string) => void;
}

export const Switch = ({ options, selected, setSelected }: Props) => (
  <div style={{ display: 'flex' }}>
    <div
      onClick={() => setSelected(options[0])}
      style={{
        ...(selected === options[0] ? selectedStyle : unselectedStyle),
        background:
          selected === options[0]
            ? `linear-gradient(to right, ${selectedBgColor} 50%, ${unselectedBgColor} 50%) right bottom / 200% 100%`
            : `linear-gradient(to right, ${selectedBgColor} 50%, ${unselectedBgColor} 50%) left bottom / 200% 100%`,
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
      }}
    >
      {options[0]}
    </div>
    <div
      onClick={() => setSelected(options[1])}
      style={{
        ...(selected === options[1] ? selectedStyle : unselectedStyle),
        background:
          selected === options[1]
            ? `linear-gradient(to left, ${selectedBgColor} 50%, ${unselectedBgColor} 50%) left bottom / 200% 100%`
            : `linear-gradient(to left, ${selectedBgColor} 50%, ${unselectedBgColor} 50%) right bottom / 200% 100%`,
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      {options[1]}
    </div>
  </div>
);
