import { Grid, Group, LoadingOverlay } from '@mantine/core';
import useSWRImmutable from 'swr/immutable';
import { useUsername } from '../../hooks/use-username.hook';
import { fetcher } from '../../shared/fetcher';
import { DateHelper } from '../../shared/helpers/date.helper';
import { Suspense } from '../../shared/components/suspense';
import { Text } from '../../shared/components/text';
import { Totals } from '../../shared/models/totals';
import { ScrobbleAverages } from './scrobbles/scrobble-averages';
import { ScrobbleDays } from './scrobbles/scrobble-days';
import { ScrobbleTotals } from './scrobbles/scrobble-totals';
import { TopOfPeriods } from './scrobbles/top-of-periods/top-of-periods';

export const Overview = () => {
  const username = useUsername();
  const { data: totals, error } = useSWRImmutable<Totals[]>(
    `/totals/${username}`,
    fetcher,
  );

  return !error ? (
    <Group
      direction="column"
      grow
      style={{ height: '100%', flexWrap: 'nowrap' }}
    >
      <div style={{ flex: '1 1 0px', position: 'relative' }}>
        <Suspense
          loading={!totals}
          suspense={<LoadingOverlay visible></LoadingOverlay>}
          content={<ScrobbleTotals totals={totals!}></ScrobbleTotals>}
        ></Suspense>
      </div>

      <Grid style={{ flexGrow: 0 }}>
        <Grid.Col span={12}>
          <TopOfPeriods></TopOfPeriods>
        </Grid.Col>
        {/* <Grid.Col span={12}>
          <ScrobbleDays></ScrobbleDays>
        </Grid.Col>
        <Grid.Col span={12}>
          <ScrobbleAverages></ScrobbleAverages>
        </Grid.Col>
        <Grid.Col span={12}>
          <ScrobbleAverages></ScrobbleAverages>
        </Grid.Col> */}
      </Grid>
    </Group>
  ) : (
    <Text
      text={`Next processing time ${DateHelper.getNextProcessingTime()} (UTC)`}
    ></Text>
  );
};
